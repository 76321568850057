<template>
  <article
    class="content"
    style="margin-top: -50px"
    itemscope
    itemtype="http://schema.org/Event"
  >
    <div class="header text-center" style="margin-bottom: 5px">
      <br />
      <p>CABO SAN LUCAS</p>
      <br />
      <h1
        class="card-title tulum-party-title mt-0 mb-0"
        style="
          font-size: 1.9rem;
          font-weight: bold;
          text-transform: uppercase;
          color: #fff !important;
        "
        itemprop="name"
      >
        {{ currentEvent.title }}
      </h1>


    <p class="event-time-location">
        <time
          itemprop="startDate"
          v-bind:content="currentEventDateNTime"
          v-bind:datetime="currentEventDateNTime"
          v-if="currentEventDateNTime"
        >
          {{ formatDate(currentEventDateNTime) }}
        </time>
        <span>{{ currentEventPlace }}</span>
        <span>
          , <span>{{ currentEventLocality }}</span>
        </span>
      </p>
      <br />
      <p
        style="
          text-transform: uppercase;
          font-weight: bolder;
          color: #ffffff !important;
        "
        v-if="currentEvent.metadata.caption"
      >
        {{ currentEvent.metadata.caption }}
      </p>
      <br />


      <img
        v-if="!this.enlargeImg "
        width="350px"
        height="350px"
        @click="this.enlargeImage"
        itemprop="image"
        v-bind:content="currentEventImage+'?w=350&h=350&fit=clamp&auto=format'"
        v-lazy="currentEventImage+'?w=350&h=350&fit=clamp&auto=format'"
        style="box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%)"
        v-bind:alt="currentEvent.title"
      />
      <img
        v-if="this.enlargeImg"
        itemprop="image"
        @click="this.enlargeImage"
        class="image-on-show"
        v-bind:content="currentEventImage"
        v-lazy="currentEventImage"
        style="box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%)"
        v-bind:alt="currentEvent.title"
      />
      <br />
      <span
        style="
          margin-top: -15px;
          font-weight: bold;
          vertical-align: top;
          font-size: 0.9rem;
          color: #ffffff;
          background: black;
          min-width: 40px;
          text-transform: uppercase;
        "
        v-if="!this.enlargeImg"
        class="tim-icons icon-zoom-split"
        >+</span
      >
      <div v-if="currentEvent.metadata.brand && currentEvent.metadata.brand.title">
        <router-link
          :to="{
            name: 'Brand',
            params: { slug: currentEvent.metadata.brand.slug },
          }"
          class="btn-link btn-sm"
          style="font-size: 0.9rem; margin-top: 5px"
        >
          <br />
          ™️ PRESENTED BY: {{ currentEvent.metadata.brand.title.toUpperCase() }}
          <br />
          <br />
        </router-link>
      </div>
      <hr />

      <p v-if="isPastEvent" class="tulum-party-subtitle">
        ⚠️ This event occurred in the past
      </p>
      <p v-if="isPastEvent">Check the list of upcoming events</p>

      <div
        class="text-center btn-group btn-group-vertical"
        style="
          margin: 10px;
          margin-bottom: 40px;
          width: 100%;
          max-width: 350px;
          background-color: #2f2c2c;
        "
      >
        <a
          v-if="currentEvent.metadata.tickets_link && !isPastEvent"
          v-bind:href="currentEvent.metadata.tickets_link"
          v-on:click="onTicketClick(currentEvent.title)"
          class="btn btn-primary"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          GET TICKETS
        </a>
        <router-link
          v-if="isPastEvent"
          :to="{ name: 'Home' }"
          class="btn btn-primary"
        >
          upcoming events
        </router-link>
      </div>

      <br />
    </div>
    <div class="card">
      <div class="card-body">
        <h3
          class="tulum-party-subtitle brand-title-black"
          style="text-transform: uppercase; font-size: 1rem"
        >
          Event Details
        </h3>
        <hr />
        <div itemprop="description">
          <div v-html="currentEvent.content" class="card-text"></div>
        </div>
        <div v-if="currentEvent.metadata.extra_content">
          <h3 class="tulum-party-subtitle brand-title-black">Extras</h3>
          <hr />
          <div
            v-html="currentEvent.metadata.extra_content"
            v-if="currentEvent.metadata.extra_content"
            class="card-text"
          ></div>
        </div>
        <a
          v-bind:href="currentEvent.metadata.event_link"
          class="btn btn-primary"
          v-if="currentEvent.metadata.event_link"
          onclick="window.ga('send', 'event', 'Event', 'Site', 'event_link' ,  1)"
        >
          EVENT SITE
        </a>

        <div style="margin-top: 5px" v-if="currentEvent.metadata.venue && currentEvent.metadata.venue.length">
          <hr />
          <h3 class="tulum-party-subtitle brand-title-black">LOCATION</h3>

          <router-link
            :to="{
              name: 'Club',
              params: { slug: currentEvent.metadata.venue.slug },
            }"
            class="btn-link btn-sm"
            style="font-size: 0.8rem"
          >
            📍 {{ currentEvent.metadata.venue.title.toUpperCase() }}
          </router-link>

          <hr />
          <span
            itemprop="location"
            itemscope=""
            itemtype="http://schema.org/Place"
          >
            <span itemprop="name">{{ currentEventPlace }}</span>
            <br />
            <span
              id="address-microdata"
              style="font-size: 10px"
              itemprop="address"
              itemscope=""
              itemtype="http://schema.org/PostalAddress"
            >
              {{ currentEventAddress }} ,
              <span itemprop="addressLocality">{{ currentEventLocality }}</span>
              <meta
                itemref="address-microdata"
                itemprop="addressCountry"
                content="MX"
              />
            </span>
          </span>
          <br />
          <br />
        </div>
        <!--        <VIDEO-->
        <div class="text-center card" style="border: 1px solid black"
             v-if="currentEvent.metadata.video && currentEvent.metadata.video.metadata"
        >
          <hr  />
          <HlsVideoPlayer
            v-bind:poster="
              currentEvent.metadata.video.metadata.previewimage.imgix_url
            "
            v-bind:content="currentEvent.metadata.video.content"
            v-bind:video-url="
              currentEvent.metadata.video.metadata.video.imgix_url.replace(
                'https://cdn.cosmicjs.com/',
                'https://imgix.cosmicjs.com/'
              )
            "
          >
          </HlsVideoPlayer>
          <!--        <PHOTOS-->

        </div>
      </div>

      <div
        class="text-center btn-group btn-group-vertical"
        style="margin: 10px; margin-bottom: 40px"
        v-if="currentEvent.metadata.tickets_link"
      >
        <a
          v-if="!isPastEvent"
          v-bind:href="currentEvent.metadata.tickets_link"
          v-on:click="onTicketClick(currentEvent.title)"
          rel="nofollow noopener noreferrer"
          target="_blank"
          class="btn btn-primary"
        >
          TICKETS
        </a>
        <p v-if="isPastEvent">
          This event occurred in the past, see the list of the upcoming events
        </p>

        <router-link
          v-if="isPastEvent"
          :to="{ name: 'Home' }"
          class="btn btn-primary"
        >
          upcoming events
        </router-link>
      </div>
      <div
        class="card text-center"
        style="
          display: flex;
          justify-content: center;
          padding: 1px;
          margin: 1px;
          margin-bottom: 0;
        "
        v-if="currentEvent.metadata.reservations_link && !isPastEvent"
      >
        <EventReservations :slug="currentEvent.slug"></EventReservations>
        <br>
        <br>
      </div>
    </div>
  </article>
</template>

<script>
import Reservations from '../../components/Reservations/Reservations';
import HlsVideoPlayer from '@/components/HlsVideoPlayer/HlsVideoPlayer';
import EventReservations from "@/components/EventCard/EventReservations";

import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'Event',
  props: ['slug'],
  metaInfo() {
    const eventKeywords = this.slug.replace(/-/g, ',').toUpperCase();
    return {
      title: `${
        this.currentEvent.title ? this.currentEvent.title : 'Loading ...'
      }`,
      meta: [
        {
          name: 'description',
          content: `${this.currentEventCaption}. Event Tickets & Reservations. ${this.cleanContent}`,
        },
        {
          name: 'keywords',
          content: `Cabo, tickets, party, reservations, bookings, ${eventKeywords}, club, nightclub`,
        },
        {
          property: 'og:title',
          content: `[cabo party] | ${this.currentEvent.title}`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/san-jose-cabo/event/${this.slug}`,
        },
        {
          property: 'og:description',
          content: `${this.currentEventCaption}. Event Tickets & Reservations.`,
        },
        { property: 'og:type', content: 'article' },
        {
          property: 'og:image',
          content: this.currentEventImage,
        },
      ],
      // script: [
      //   { innerHTML: '{ "@context": "http://www.schema.org", "@type": "Organization" }', type: 'application/ld+json' },
      //   { innerHTML: '{ "body": "yes" }', body: true, type: 'application/ld+json' }
      // ],
    };
  },
  created() {
    this.$store.dispatch('getCaboParty', { slug: this.slug });
  },
  data: () => {
    return {
      table: [],
      enlargeImg: false,
      showReservations: false,
    };
  },
  filters: {
    formattedDate: function attachPrettyDate(start_time) {
      const partyDate = start_time;
      const format = 'dddd';
      return moment(partyDate).calendar({ format: format });
    },
  },
  components: {  EventReservations, HlsVideoPlayer },
  methods: {
    onTicketClick(event) {
      window.ga('send', 'event', 'Event', 'Tickets', 'ticket', 3);
      this.$rollbar.info(`CABO: Ticket link: ${event}`);
    },
    enlargeImage(event) {
      this.enlargeImg = !this.enlargeImg;
    },
    formatDate(date) {
      return moment(date).format('dddd, DD MMMM YYYY, hh:mma')
    }
  },
  computed: mapState({
    currentEvent: (state) => state.caboparty,
    currentEventCaption: (state) =>
      state.caboparty.metadata.caption ? state.caboparty.metadata.caption : '',
    currentEventImage: (state) =>
      state.caboparty.metadata.main_image &&
      state.caboparty.metadata.main_image.imgix_url,
    currentEventPlace: (state) =>
      state.caboparty.metadata.venue ? state.caboparty.metadata.venue.title : null,
    currentEventLocality: (state) => {
        return 'Cabo San Lucas';
    },
    currentEventAddress: (state) =>
      state.caboparty.metadata.venue
        ? state.caboparty.metadata.venue.metadata.address
        : 'TBA',
    isPastEvent: (state) => {
      if (state.caboparty.metadata && state.caboparty.metadata.date) {
        return (
          moment.utc(state.caboparty.metadata.date).endOf('day') <=
          moment().startOf('day')
        );
      }
    },
    currentEventDateNTime: (state) =>
      state.caboparty.metadata.date &&
      state.caboparty.metadata.date +
        'T' +
        state.caboparty.metadata.start_time +
        '-05:00',
    cleanContent: (state) =>
      state.caboparty.content &&
      state.caboparty.content.replace(/(<([^>]+)>)/gi, '').substr(0, 200) +
        '&hellip;',
  }),
};
</script>

<style scoped></style>
